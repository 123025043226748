import React, { Component } from 'react'
import Blockly from 'blockly'
import ReactBlockly from 'react-blockly'
import Tone from 'tone'
import zlib from 'zlib'

import {init_blocks, block_defs, theme_defs} from './Blocks'
import {getCharacter} from './Text'
import Draggable from './Draggable'

// referenced from generated code in Blocks.js
var app = null

const DEFAULT_WORKSPACE = '<xml xmlns="https://developers.google.com/blockly/xml"></xml>'

const BOT_START_ROW = 1
const BOT_START_COL = 24

function random(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function mod(n, m) {
  return ((n % m) + m) % m;
}

// should this be called with tighter scope?
init_blocks()

////////////////////////////////////////////////////////////////////////////////
async function pauseFor(timeoutMs) {
    let promise = new Promise((resolve, reject) => {
                                setTimeout(() => resolve("done!"), timeoutMs)
                              });
    await promise;
}

////////////////////////////////////////////////////////////////////////////////
export default class App extends Component {
  constructor(props) {
    super(props)

    this.share = this.share.bind(this)
    this.copy = this.copy.bind(this)

    this.reset = this.reset.bind(this)
    this.go = this.go.bind(this)
    this.stop = this.stop.bind(this)
    this.clear = this.clear.bind(this)
    this.toggleGridNumbers = this.toggleGridNumbers.bind(this)

    this.workspaceDidChange = this.workspaceDidChange.bind(this)

    this.onKeyDown = this.onKeyDown.bind(this)
    this.up = this.up.bind(this)
    this.down = this.down.bind(this)
    this.left = this.left.bind(this)
    this.right = this.right.bind(this)
    this.updateCursor = this.updateCursor.bind(this)

    this.tapUp = this.tapUp.bind(this)
    this.tapDown = this.tapDown.bind(this)
    this.tapLeft = this.tapLeft.bind(this)
    this.tapRight = this.tapRight.bind(this)

    this.eatCallback = async function (colourEaten) {}
    this.moveCallback = async function () {}

    this.workspaceXml = DEFAULT_WORKSPACE
    // ugly variable to determine whether to load workspace from URL on page load
    this.loaded = false

    // app state
    this.state = {
      characterRow:BOT_START_ROW,
      characterCol:BOT_START_COL,
      mood:"happy",
      board: this.startBoard(),
      running: false,
      shortLink:null,
      shortLinkMessage:null,
      gridNumbers:false,
      loading:true
    }
    this.code = ""
    this.synthLead = new Tone.Synth().toMaster()
    this.synthNoise = new Tone.NoiseSynth().toMaster()
  }

  startBoard() {
    return [
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,1,1,1,1, 2,2,2,2,2, 0,3,3,3,0, 4,4,4,4,0, 5,5,5,5,5, 6,6,6,6,0, 0,7,7,7,0, 8,8,8,8,8, 0,2,2,2,2, 0,0],
        [0,0, 1,1,0,0,0, 0,0,2,0,0, 3,0,0,0,3, 4,0,0,0,4, 0,0,5,0,0, 6,0,0,0,6, 7,7,7,7,7, 0,0,8,0,0, 2,2,0,0,0, 0,0],
        [0,0, 0,1,1,1,0, 0,0,2,0,0, 3,3,3,3,3, 4,4,4,4,0, 0,0,5,0,0, 6,6,6,6,0, 7,0,7,0,7, 0,0,8,0,0, 0,2,2,2,0, 0,0],
        [0,0, 0,0,0,1,1, 0,0,2,0,0, 3,0,0,0,3, 4,0,0,0,4, 0,0,5,0,0, 6,0,0,0,6, 7,7,7,7,7, 0,0,8,0,0, 0,0,0,2,2, 0,0],
        [0,0, 1,1,1,1,0, 0,0,2,0,0, 3,0,0,0,3, 4,0,0,0,4, 0,0,5,0,0, 6,6,6,6,0, 0,7,7,7,0, 0,0,8,0,0, 2,2,2,2,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0],
        [0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0,0,0,0, 0,0]
      ]
  }

  clear() {
    var clearBoard = this.state.board
    for (var row in clearBoard) {
      for (var col in clearBoard[row]) {
        clearBoard[row][col] = 0
      }
    }
    this.setState({board:clearBoard})
  }

  boardCount(board, colour) {
      return board.reduce(function(a,b) { return a.concat(b) }) // flatten array
        .reduce(function(a,b) {
           if (b === colour) return 1 + a;
           else return a;
         });                // sum
  }

  countRemaining(colour) {
      var count = this.boardCount(this.state.board, colour);
      // HACK! if there's a square under the character that hasn't been removed from the board
      // yet (beacuse of iffy event updating logic in paintBoard()) then don't count it towards the total.
      if (this.state.board[this.state.characterRow][this.state.characterCol] === colour) {
        count = count - 1
      }
      return count
  }

  componentDidMount() {
    // is this the best way to store self for use by eval() code?
    app = this
    this.reset()

    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  render() {
    // await font loading before render to avoid text overlap issues in Blockly
    if (this.state.loading) {
      var self = this
      // get it to render once the fonts have loaded
      document.fonts.ready.then(function(fontFaceSetEvent) {
        self.setState({loading:false});
      });
      // backup timer in case it doesn't fire (benign if the above already done)
      setTimeout(function (fontFaceSetEvent) {self.setState({loading:false});}, 2000)
      // render the things to get the loading API to resolve the ready promise
      return (<React.Fragment>
                <div className="loading">Loading</div>
                <div>One sec...</div>
              </React.Fragment>)
    }

    // is it time to load the workspace from the URL?
    const compressed = window.location.href.split('?')[1]
    if (!this.loaded && compressed !== "" && compressed !== undefined) {
      const decompressed = zlib.inflateSync(new Buffer(this.base64FromUrlSafe(compressed), 'base64')).toString()

      this.workspaceXml = '<xml xmlns="https://developers.google.com/blockly/xml">' + decompressed;
      this.loaded = true
    }

    // render the lot
    return (
      <React.Fragment>
        <div className="header">
          <div className="left">Toolbox</div>
          <div className="midleft">Workspace</div>
          <div className="rightImg"><img className="bannerImg" src="banner.png" alt='Startbots'></img></div>
          <div className="right">
          {this.state.shortLink !== null ?
            <div><div className="link">{this.state.shortLink}</div><div className="linkType" onClick={this.copy}>({this.state.shortLinkMessage})</div></div>
            :
            <div className="share" onClick={this.share}>Get a link to share this code</div>
          }
          </div>
        </div>

        <div className='blockly'>
          <ReactBlockly.BlocklyEditor
            initialXml={this.workspaceXml}
            toolboxCategories={block_defs}
            workspaceConfiguration={theme_defs}
            wrapperDivClassName="fill-height"
            workspaceDidChange={this.workspaceDidChange}
          />
        </div>

        <div className={this.state.running ? "controls running" : "controls"}>
          <div className="gridshow" onClick={this.toggleGridNumbers}>
            {this.state.gridNumbers ?
              "Hide row (⇨) and column (⇩) numbers. O-bot is at row " + (this.state.characterRow+1) + ", column " + (this.state.characterCol+1)
              :
              "Show row (⇨) and column (⇩) numbers"}
          </div>
          <div className="mainControls">
          {this.state.running ?
            <div className="button stop" onClick={this.stop}>Stop</div>
            :
            <div className="button run" onClick={this.go}>Go!</div>
          }
          <div className="button restart" onClick={this.reset}>Reset</div>
          <div className="button clear" onClick={this.clear}>Clear</div>
          </div>
          <div className="spacer"></div>
        </div>

        <Draggable initialPos={{x:window.innerWidth - 205, y:35}}>
          <div className="tapControls">
          <div className="arrow" onClick={this.tapUp}>⇧</div>
          <div>
            <div className="arrow" onClick={this.tapLeft}>⇦</div>
            <div className="arrow" onClick={this.tapDown}>⇩</div>
            <div className="arrow" onClick={this.tapRight}>⇨</div>
          </div>
          </div>
        </Draggable>

        <table>
          <tbody>
            {this.state.board.map((innerArray, row) => (
                <tr key={row}>
                    {innerArray.map((item, col) => <td key={row+'-'+col}
                      className={'cell ' + (
                        // is this cell the character?
                        row === this.state.characterRow && col === this.state.characterCol ?
                          'character ' + this.state.mood :
                          // not character - is this cell live or empty?
                          item > 0 ? 'letter'+item :
                          // whyyyyyy so much ternary in JSX?
                            (row === this.state.characterRow) || (col === this.state.characterCol) ?
                              (this.state.characterRow !== 0 && this.state.characterCol !== 0) ? 'emptyBright' : 'empty' : 'empty')
                        }>{this.state.gridNumbers ? row === 0 ? col+1 : col === 0 ? row+1 : '' : ''}</td>)
                      }
                </tr>
            ))}
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  copyInternal(url) {
    // write to the clipboard
    var self = this
    navigator.clipboard.writeText(url).then(function() {
        // clipboard successfully set
        self.setState({shortLinkMessage: "copied"});
      }, function() {
        // clipboard write failed
        self.setState({shortLinkMessage: "copy me!"});
    })
  }

  copy() {
    this.copyInternal(this.state.shortLink)
  }

  toggleGridNumbers() {
    this.setState({gridNumbers:!this.state.gridNumbers})
  }

  share() {
    // thank heavens for TinyURL and their token-free API!
    fetch("https://tinyurl.com/api-create.php?url=" + window.location.href)
      .then(res => res.text())
      .then(
        (result) => {
          this.setState({shortLink: result, shortLinkMessage: "copy me!"});

          // write to the clipboard
          this.copyInternal(result)
        },
        // handle errors here instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({shortLink: null});
        }
      )
  }

  workspaceDidChange (workspace) {
    // save the workspace for later
    this.workspaceXml = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
    // generate code for later too (could this not be done at eval() time?)
    this.code = Blockly.JavaScript.workspaceToCode(workspace)
    // compress the workspace into something that'll fit in a URL
    const trimmed = this.workspaceXml.replace('<xml xmlns="https://developers.google.com/blockly/xml">', '')
    // may as well use max compression (level:9) as files are small enough to compress fast
    // and a little experimentation shows it does give better results for larger xml
    const deflated = zlib.deflateSync(trimmed, {level:9}).toString('base64');
    // bake a nice URL
    const url = window.location.href.split('?')[0] + '?' + this.urlSafeBase64(deflated)
    // poke it into the address bar
    window.history.pushState({page: 'Startbots Code Lab'}, 'Startbots Code Lab', url);
    // invalidate our short link
    this.setState({shortLink: null});
  }

  go() {
    Blockly.JavaScript.addReservedWords('code');

    // insert return statements to allow us to stop().
    // really crude approach - any JSON in the code will get mangled.
    // note that this can't instrument calls to app.something() from the generated code.
    const instrumented = this.code.replace(/{/g, "{ if (app.shouldStop()) return;")

    // wrap in an async function to allow us to use await for delays.
    // swtich off running state at end of execution.
    // add dummies for callbacks to clear anything from previous runs
    // dummy their parameter(s) to prevent catastrophe if used in error (how can we alert / prevent this?)
    const code = "app.eatCallback = async function(colourEaten) {}; \
                  app.moveCallback = async function() {}; \
                  var _colourEaten = 0; \
                  async function runBlockly() {" + instrumented + ";app.setState({running:false})} \
                  runBlockly(); ";

    // not stopping, yet
    this.running = true;
    this.setState({running:true})

    try {
        eval(code);
    } catch (e) {
        alert(e);
    }
  }

  reset() {
    this.stop();
    this.setState({ board:this.startBoard(),
                    characterRow:BOT_START_ROW,
                    characterCol: BOT_START_COL,
                    mood:"happy"})
  }

  shouldStop() {
    return !this.running;
  }

  stop() {
    // wait for instrmented code to pick up on this
    this.running = false;
    // update the UI too (can't use React state for the above)
    this.setState({running:false})
  }

  urlSafeBase64(base64) {
    return base64.replace(/\+/g, '.').replace(/\//g, '_').replace(/=/g, '-');
  }
  base64FromUrlSafe(urlSafe) {
    return urlSafe.replace(/\./g, '+').replace(/_/g, '/').replace(/-/g, '=');
  }

  async character(char, colour) {
    // well this is ugly.... :D
    const pause = 15;
    for (let row=0; row < 5; ++row) {
      if (app.shouldStop()) return;
      if ((row % 2) === 0) {
        for (let col=0; col < 4; ++col) {
          // paint ->
          this.right(char[row][col] !== 0 ? colour : 0)
          await pauseFor(pause);
        }
        if (row < 4) {
          // down a row
          this.down(char[row][4] !== 0 ? colour : 0)
          await pauseFor(pause);
        }
        else {
          // move the cursor to start of next letter
          this.right(char[row][4] !== 0 ? colour : 0)
          await pauseFor(pause);
          for (let i=0; i < 4; ++i) {
              this.up(0)
              await pauseFor(pause);
          }
        }
      }
      else {
        // paint <-
        for (let col=4; col > 0; --col) {
          this.left(char[row][col] !== 0 ? colour : 0)
          await pauseFor(pause);
        }
        // down a row
        this.down(char[row][0] !== 0 ? colour : 0)
        await pauseFor(pause);
      }
    }
    // do a final space (would have been the start of the next character)
    this.right(0)
    await pauseFor(15);
  }

  async writeString(str, colour) {
    // coerce to a string
    const printable = (" " + str).trim()

    for (let i=0; i < printable.length; ++i) {
      if (app.shouldStop()) return;
      await this.character(getCharacter(printable.charAt(i)), colour)
    }
  }

  paintBoardInternal(row, col, colour) {
      // wrap paints as well as moves
      row = mod(row, this.state.board.length)
      col = mod(col, this.state.board[0].length)

      var newBoard = this.state.board
      newBoard[row][col] = colour
      return newBoard
    }

  paintCoord(row, col, colour) {
    this.setState({board:this.paintBoardInternal(row, col, colour)})
  }

  async setCursor(newRow, newCol) {
    this.updateCursor(newRow - 1, newCol - 1, 0)
  }

  async updateCursor(newRow, newCol, colour, fromUserInput) {
    const cellEaten = this.state.board[newRow][newCol]
    // HACK: fix this, should paint based on the new position, not the old one!
    // see extra hack in countBoard() above (ick)
    const newBoard = this.paintBoardInternal(this.state.characterRow, this.state.characterCol, colour)

    // choose a note to play
    if (cellEaten > 0) {
      // a coloured block was eaten, do default behaviour
      const scale = ["C", "D", "E", "F", "G", "A", "B", "C", "D", "E"]
      const note = scale[cellEaten-1] + (9-this.state.characterRow)
      this.synthLead.triggerAttackRelease(note, "8n")
    }
    else if (colour) {
      // a move and paint occurred
      this.synthNoise.triggerAttackRelease("16n")
    }

    this.setState({board:newBoard, characterCol:newCol, characterRow: newRow},  async function () {
      // trigger any user move/eat code
      if (fromUserInput) {
        if (cellEaten > 0) {
           await this.eatCallback(cellEaten)
        }
        await this.moveCallback()
      }
    })
  }

  setMood(mood) {
    this.setState({mood:mood})
  }

  left(colour, fromUserInput) {
    // wrap left
    const newCol = mod(this.state.characterCol - 1, this.state.board[0].length)
    this.updateCursor(this.state.characterRow, newCol, colour, fromUserInput)
  }
  right(colour, fromUserInput) {
    // wrap right
    const newCol = mod(this.state.characterCol + 1, this.state.board[0].length)
    this.updateCursor(this.state.characterRow, newCol, colour, fromUserInput)
  }
  up(colour, fromUserInput) {
    // wrap up
    const newRow = mod(this.state.characterRow - 1, this.state.board.length)
    this.updateCursor(newRow, this.state.characterCol, colour, fromUserInput)
  }
  down(colour, fromUserInput) {
    // wrap down
    const newRow = mod(this.state.characterRow + 1, this.state.board.length)
    this.updateCursor(newRow, this.state.characterCol, colour, fromUserInput)
  }

  tapUp() {
    this.up(0, true);
  }
  tapDown() {
    this.down(0, true);
  }
  tapLeft() {
    this.left(0, true);
  }
  tapRight() {
    this.right(0, true);
  }

  onKeyDown(event) {
    // movement handlers. TODO - how to do this on touchscreens?
    if (event.key === "ArrowLeft") {
      event.preventDefault()
      this.left(0, true)
    }
    else if (event.key === "ArrowRight") {
      event.preventDefault()
      this.right(0, true)
    }
    else if (event.key === "ArrowUp") {
      event.preventDefault()
      this.up(0, true)
    }
    else if (event.key === "ArrowDown") {
      event.preventDefault()
      this.down(0, true)
    }
  }
}
