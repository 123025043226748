import React, { Component } from 'react'

export default class Draggable extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      counter: this.props.counter,
      pos: this.props.initialPos,
      dragging: false,
      rel: null // position relative to the cursor
    };
  }

  /* we could get away with not having this (and just having the listeners on
   our div), but then the experience would be possibly be janky. If there's
   anything w/ a higher z-index that gets in the way, then you're toast,
   etc.*/
  componentDidUpdate(props, state) {
    if (this.state.dragging && !state.dragging) {
      document.addEventListener('touchmove', this.onTouchMove);
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('touchend', this.onTouchEnd);
      document.addEventListener('mouseup', this.onMouseUp);
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener('touchmove', this.onTouchMove);
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('touchend', this.onTouchEnd);
      document.removeEventListener('mouseup', this.onMouseUp);
    }
  }

  // calculate relative position to the mouse and set dragging=true
  onMouseDown = (e) => {
    if (e.button !== 0) return;
    let pos = { left: this.myRef.current.offsetLeft, top: this.myRef.current.offsetTop }
    this.setState({
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
        y: e.pageY - pos.top
      }
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onMouseUp = (e) => {
    this.setState({ dragging: false });
    e.stopPropagation();
    e.preventDefault();
  }

  onMouseMove = (e) => {
    if (!this.state.dragging) return;

    this.setState({
      pos: {
        x: e.pageX - this.state.rel.x,
        y: e.pageY - this.state.rel.y
      }
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onTouchStart = (e) => {
    const touch = e.changedTouches[0];
    const pos = { left: this.myRef.current.offsetLeft, top: this.myRef.current.offsetTop }
    this.setState({
      dragging: true,
      rel: {
        x: touch.pageX - pos.left,
        y: touch.pageY - pos.top
      }
    });
    e.stopPropagation();
  }
  onTouchEnd = (e) => {
    this.setState({ dragging: false });
    e.stopPropagation();
  }
  onTouchMove = (e) => {
    const touch = e.changedTouches[0];
    if (!this.state.dragging) return;

    this.setState({
      pos: {
        x: touch.pageX - this.state.rel.x,
        y: touch.pageY - this.state.rel.y
      }
    });
    e.stopPropagation();
  }

  render() {
    // to make it actually draggable, add these to the <span> below
    // onTouchStart={this.onTouchStart} onMouseDown={this.onMouseDown}
    return (
      <span className='draggable' ref={this.myRef} style={{ position: 'absolute', left: this.state.pos.x + 'px', top: this.state.pos.y + 'px' }}>
        {this.props.children}
      </span>
    )
  }
}
