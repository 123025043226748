import Blockly from 'blockly'

const MOVE_COLOUR = "#e46043"
const NUMBERS_COLOUR = "#e62565"
const CONTROLS_COLOUR = "#9b2fae"
const FUNCTIONS_COLOUR = "#4054b2"
const VARIABLES_COLOUR = "#149588"
const DRAWING_COLOUR = "#50ae54"
const EVENTS_COLOUR = "#eeb01e"

export function init_blocks() {
  Blockly.FieldColour.COLOURS = [
      '#fee94e', '#fec02e', '#e46043',
      '#e62565', '#9b2fae', '#4054b2',
      '#149588', '#50ae54', '#eeeeee'];
  Blockly.FieldColour.TITLES = [
      '1', '2', '3',
      '4', '5', '6',
      '7', '8', '9'];
  Blockly.FieldColour.COLUMNS = 3;

  Blockly.Blocks['colour'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(new Blockly.FieldColour("#fee94e"), "COLOUR");
        this.setOutput(true, null);
        this.setColour("#555");
        this.setTooltip("");
        this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['colour'] = function(block) {
    const colour = 1+Blockly.FieldColour.COLOURS.indexOf(block.getFieldValue('COLOUR'));
    return [colour, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['paint'] = {
    init: function() {
      this.appendValueInput("ROW")
          .setCheck("Number")
          .appendField("draw row")
      this.appendValueInput("COLUMN")
          .setCheck("Number")
          .appendField("column")
      this.appendValueInput("COLOUR")
          .appendField("in")
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(DRAWING_COLOUR);
      this.setInputsInline(true);
    }
  }
  Blockly.JavaScript['paint'] = function(block) {
    const row = Blockly.JavaScript.valueToCode(block, 'ROW', Blockly.JavaScript.ORDER_ATOMIC);
    const col = Blockly.JavaScript.valueToCode(block, 'COLUMN', Blockly.JavaScript.ORDER_ATOMIC);
    const colour = Blockly.JavaScript.valueToCode(block, 'COLOUR', Blockly.JavaScript.ORDER_ATOMIC);

    return 'app.paintCoord(' + row + ' - 1,' + col + ' - 1,' + colour + ');\n';
  };

  Blockly.Blocks['clear'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("clear the screen");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(DRAWING_COLOUR);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  }
  Blockly.JavaScript['clear'] = function(block) {
    return 'app.clear();\n';
  };

  Blockly.Blocks['draw'] = {
    init: function() {
      this.appendValueInput("STEPS")
          .setCheck("Number")
          .appendField("paint")
          .appendField(new Blockly.FieldDropdown([["right ⇨","right"], ["left ⇦","left"], ["up ⇧","up"], ["down ⇩","down"]]), "DIRECTION");
      this.appendValueInput("COLOUR")
          .appendField("steps in")
      this.setColour(MOVE_COLOUR);
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['draw'] = function(block) {
    const move = block.getFieldValue('DIRECTION');
    const colour = Blockly.JavaScript.valueToCode(block, 'COLOUR', Blockly.JavaScript.ORDER_ATOMIC)
    const steps = Blockly.JavaScript.valueToCode(block, 'STEPS', Blockly.JavaScript.ORDER_ATOMIC);
    return 'for (let i=0; i<'+steps+'; ++i) {app.' + move + '(' + colour + '); await pauseFor(50);}\n'
  };

  Blockly.Blocks['move'] = {
    init: function() {
      this.appendValueInput("STEPS")
          .setCheck("Number")
          .appendField("move")
          .appendField(new Blockly.FieldDropdown([["right ⇨","right"], ["left ⇦","left"], ["up ⇧","up"], ["down ⇩","down"]]), "DIRECTION");
      this.appendDummyInput()
          .appendField("steps");
      this.setColour(MOVE_COLOUR);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['move'] = function(block) {
    const move = block.getFieldValue('DIRECTION');
    const steps = Blockly.JavaScript.valueToCode(block, 'STEPS', Blockly.JavaScript.ORDER_ATOMIC);
    return 'for (var i=0; i<'+steps+'; ++i) {app.' + move + '(0); await pauseFor(25);}\n'
  };

  Blockly.Blocks['teleport'] = {
    init: function() {
      this.appendValueInput("ROW")
          .setCheck("Number")
          .appendField("teleport to row");
      this.appendValueInput("COLUMN")
          .setCheck("Number")
          .appendField("column");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(MOVE_COLOUR);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['teleport'] = function(block) {
    const row = Blockly.JavaScript.valueToCode(block, 'ROW', Blockly.JavaScript.ORDER_ATOMIC);
    const col = Blockly.JavaScript.valueToCode(block, 'COLUMN', Blockly.JavaScript.ORDER_ATOMIC);

    return 'await app.setCursor(' + row + ', ' + col + ');\n';
  };

  Blockly.Blocks['write'] = {
  init: function() {
    this.appendValueInput("STRING")
        .appendField("write");
    this.appendValueInput("COLOUR")
        .appendField("in")
    this.setColour(MOVE_COLOUR);
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setTooltip("");
    this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['write'] = function(block) {
    const msg = Blockly.JavaScript.valueToCode(block, 'STRING', Blockly.JavaScript.ORDER_ATOMIC);
    const colour = Blockly.JavaScript.valueToCode(block, 'COLOUR', Blockly.JavaScript.ORDER_ATOMIC);

    return 'await app.writeString(' + msg + ', ' + colour + ')\n';
  };

  const img_width=16
  Blockly.Blocks['mood'] = {
    init: function() {
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_CENTRE)
          .appendField("set O-bot's mood to")
          .appendField(new Blockly.FieldDropdown([[{"src":"bot-happy.svg","width":img_width,"height":img_width,"alt":"happy"},"happy"],
                                                  [{"src":"bot-laughing.svg","width":img_width,"height":img_width,"alt":"laughing"},"laughing"],
                                                  [{"src":"bot-unicorn.svg","width":img_width,"height":img_width,"alt":"unicorn"},"unicorn"],
                                                  [{"src":"bot-cheeky.svg","width":img_width,"height":img_width,"alt":"cheeky"},"cheeky"],
                                                  [{"src":"bot-sleeping.svg","width":img_width,"height":img_width,"alt":"sleeping"},"sleeping"],
                                                  [{"src":"bot-neutral.svg","width":img_width,"height":img_width,"alt":"neutral"},"neutral"],
                                                  [{"src":"bot-sad.svg","width":img_width,"height":img_width,"alt":"sad"},"sad"],
                                                  [{"src":"bot-scared.svg","width":img_width,"height":img_width,"alt":"scared"},"scared"],
                                                  [{"src":"bot-dead.svg","width":img_width,"height":img_width,"alt":"dead"},"dead"]
                                                ]), "MOOD");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(MOVE_COLOUR);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['mood'] = function(block) {
    const mood = block.getFieldValue('MOOD');
    return 'app.setMood("' + mood + '");\n';
  };

  Blockly.Blocks['random'] = {
    init: function() {
      this.appendValueInput("FROM")
          .setCheck("Number")
          .appendField("random from");
      this.appendValueInput("TO")
          .setCheck("Number")
          .appendField("to");
      this.appendDummyInput();
      this.setOutput(true, null);
      this.setColour(NUMBERS_COLOUR);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['random'] = function(block) {
    const from = Blockly.JavaScript.valueToCode(block, 'FROM', Blockly.JavaScript.ORDER_ATOMIC);
    const to = Blockly.JavaScript.valueToCode(block, 'TO', Blockly.JavaScript.ORDER_ATOMIC);
    const code = 'random(' + from + ',' + to + ')';

    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['count'] = {
    init: function() {
      this.appendValueInput("COLOUR")
          .setCheck(null)
          .appendField("number of");
      this.appendDummyInput()
          .appendField("squares on grid");
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(NUMBERS_COLOUR);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['count'] = function(block) {
    const colour = Blockly.JavaScript.valueToCode(block, 'COLOUR', Blockly.JavaScript.ORDER_ATOMIC);
    // TODO: Assemble JavaScript into code variable.
    const code = 'app.countRemaining(' + colour + ')';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['on_eat'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("when a square is eaten");
      this.appendStatementInput("CODE")
          .setCheck(null)
      this.setColour(EVENTS_COLOUR);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['on_eat'] = function(block) {
    const code = Blockly.JavaScript.statementToCode(block, 'CODE');
    return 'app.eatCallback = async function(_colourEaten) {' + code + '};\n';
  };

  Blockly.Blocks['colour_eaten'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("colour eaten");
      this.setOutput(true, null);
      this.setColour(EVENTS_COLOUR);
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['colour_eaten'] = function(block) {
    return ['_colourEaten', Blockly.JavaScript.ORDER_NONE];
  };

  Blockly.Blocks['on_move'] = {
    init: function() {
      this.appendDummyInput()
          .appendField("when O-bot moves");
      this.appendStatementInput("CODE")
          .setCheck(null)
      this.setColour(EVENTS_COLOUR);
      this.setTooltip("");
      this.setHelpUrl("");
    }
  };
  Blockly.JavaScript['on_move'] = function(block) {
    const code = Blockly.JavaScript.statementToCode(block, 'CODE');
    return 'app.moveCallback = async function() {' + code + '};\n';
  };

  // disable functions with returns - they're too complicated and we don't need
  // them. Note - if these are later re-enabled by removing the below lines,
  // make sure to add the funciton generator overrides for 'procedures_defreturn'
  // and for 'procedures_callnoreturn'
  Blockly.Blocks['procedures_defreturn'] = null;
  Blockly.Blocks['procedures_ifreturn'] = null;

  // need to update existing blockly function code to use async/await
  Blockly.JavaScript['procedures_defnoreturn'] = function(block) {
    // Define a procedure with a return value.
    var funcName = Blockly.JavaScript.variableDB_.getName(
        block.getFieldValue('NAME'), Blockly.Procedures.NAME_TYPE);
    var branch = Blockly.JavaScript.statementToCode(block, 'STACK');
    if (Blockly.JavaScript.STATEMENT_PREFIX) {
      var id = block.id.replace(/\$/g, '$$$$');  // Issue 251.
      branch = Blockly.JavaScript.prefixLines(
          Blockly.JavaScript.STATEMENT_PREFIX.replace(/%1/g,
          '\'' + id + '\''), Blockly.JavaScript.INDENT) + branch;
    }
    if (Blockly.JavaScript.INFINITE_LOOP_TRAP) {
      branch = Blockly.JavaScript.INFINITE_LOOP_TRAP.replace(/%1/g,
          '\'' + block.id + '\'') + branch;
    }
    var returnValue = Blockly.JavaScript.valueToCode(block, 'RETURN',
        Blockly.JavaScript.ORDER_NONE) || '';
    if (returnValue) {
      returnValue = Blockly.JavaScript.INDENT + 'return ' + returnValue + ';\n';
    }
    var args = [];
    for (var i = 0; i < block.arguments_.length; i++) {
      args[i] = Blockly.JavaScript.variableDB_.getName(block.arguments_[i],
          Blockly.Variables.NAME_TYPE);
    }
    var code = 'async function ' + funcName + '(' + args.join(', ') + ') {\n' +
        branch + returnValue + '}';
    code = Blockly.JavaScript.scrub_(block, code);
    // Add % so as not to collide with helper functions in definitions list.
    Blockly.JavaScript.definitions_['%' + funcName] = code;

    return null;
  };

  Blockly.JavaScript['procedures_callnoreturn'] = function(block) {
    // Call a procedure with no return value.
    var funcName = Blockly.JavaScript.variableDB_.getName(
        block.getFieldValue('NAME'), Blockly.Procedures.NAME_TYPE);
    var args = [];
    for (var i = 0; i < block.arguments_.length; i++) {
      args[i] = Blockly.JavaScript.valueToCode(block, 'ARG' + i,
          Blockly.JavaScript.ORDER_COMMA) || 'null';
    }
    return 'await ' + funcName + '(' + args.join(', ') + ');\n';
  };
}


export const block_defs =
[
  {
    name: 'O-bot',
    colour:MOVE_COLOUR,
    blocks: [
        {type: "move",
        values: {
            STEPS: {
              type: 'math_number',
              shadow: true,
              fields: {
                NUM: 1,
              }
            }
          }
        },
        {type: "teleport",
        values: {
            ROW: {
              type: 'math_number',
              shadow: true,
              fields: {
                NUM: 1,
              }
            },
            COLUMN: {
              type: 'math_number',
              shadow: true,
              fields: {
                NUM: 1,
              }
            }
          }
        },
        {type: "draw",
        values: {
            STEPS: {
              type: 'math_number',
              shadow: true,
              fields: {
                NUM: 1,
              }
            },
            COLOUR: {
              type: 'colour',
              shadow: true,
              fields: {
                COLOUR: 1,
              }
            }
          }
        },
        {type: "write",
        values: {
            STRING: {
              type: 'text',
              shadow: true,
              fields: {
                TEXT: "hello",
              }
            },
            COLOUR: {
              type: 'colour',
              shadow: true,
              fields: {
                COLOUR: 1,
              }
            }
          }
        },
        {type: "mood"
        }
    ]
  },
  {
    name: 'Numbers',
    colour:NUMBERS_COLOUR,
    blocks: [
      {type: "math_number",
        fields: {
          NUM: 1,
        }
      },
      {
        kind: "block",
        type: "math_arithmetic"
      },
      {type: "random",
      values: {
          FROM: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 1,
            }
          },
          TO: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 12,
            }
          }
        }
      }
      ,{type:"count",
        values: {
          COLOUR: {
            type: 'colour',
            shadow: true,
            fields: {
              COLOUR: 1
            }
          }
        }
      }
    ]
  },
  {
    name: 'Controls',
    colour:CONTROLS_COLOUR,
    blocks: [
      {
        kind: "block",
        type: "controls_if"
      },
      {
        kind: "block",
        type: "logic_compare"
      },
      {
        kind: "block",
        type: "controls_repeat_ext",
        values: {
          TIMES: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 10,
            }
          }
        }
      },
      {
        kind: "block",
        type: "controls_for",
        values: {
          FROM: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 1,
            }
          },
          TO: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 12,
            }
          },
          BY: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 1,
            }
          }
        }
      }
    ]
  },
  {
    kind: "category",
    name: "Functions",
    custom: "PROCEDURE",
    colour:FUNCTIONS_COLOUR
  },
  {
    kind: "category",
    name: "Variables",
    custom: "VARIABLE",
    colour:VARIABLES_COLOUR,
  },
  {
    kind: "category",
    name: "Drawing",
    colour:DRAWING_COLOUR,
    blocks: [
      {type: "paint",
      values: {
          ROW: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 1,
            }
          },
          COLUMN: {
            type: 'math_number',
            shadow: true,
            fields: {
              NUM: 1,
            }
          },
          COLOUR: {
            type: 'colour',
            shadow: true,
            fields: {
              COLOUR: 1,
            }
          }
        }
      },
      {type: "clear"
      },
      {type: "colour"
      }
    ]
  },
  {
    kind: "category",
    name: "Events",
    colour:EVENTS_COLOUR,
    blocks: [
      {type: "on_eat"
      },
      {type: "colour_eaten"
      },
      {type: "on_move"
      }
    ]
  }
]

// https://developers.google.com/blockly/guides/configure/web/configuration_struct?hl=en
export const theme_defs = {
  media: " ",
  grid: {
    spacing: 20,
    length: 3,
    colour: '#ddd',
    snap: true,
  },
  maxInstances: {
    'on_eat' : 1,
    'on_move' : 1
  },
  renderer: "thrasos",
  zoom: {
    controls: true,
    wheel: false,
    startScale: 1.0,
    maxScale: 1.5,
    minScale: 0.5,
    scaleSpeed: 1.2,
    pinch: false
  },
  theme: {
    base: Blockly.Themes.Classic,
      blockStyles: {
         math_blocks: {
            colourPrimary: NUMBERS_COLOUR,
            colourSecondary:"#f6a5c5",
            colourTertiary:NUMBERS_COLOUR
         },
         number_blocks: {
            colourPrimary: CONTROLS_COLOUR,
            colourSecondary:CONTROLS_COLOUR,
            colourTertiary:CONTROLS_COLOUR
         },
         logic_blocks: {
            colourPrimary: CONTROLS_COLOUR,
            colourSecondary:CONTROLS_COLOUR,
            colourTertiary:CONTROLS_COLOUR
         },
         loop_blocks: {
            colourPrimary: CONTROLS_COLOUR,
            colourSecondary:CONTROLS_COLOUR,
            colourTertiary:CONTROLS_COLOUR
         },
        procedure_blocks: {
           colourPrimary: FUNCTIONS_COLOUR,
           colourSecondary:FUNCTIONS_COLOUR,
           colourTertiary:FUNCTIONS_COLOUR
        },
        variable_blocks: {
           colourPrimary: VARIABLES_COLOUR,
           colourSecondary:VARIABLES_COLOUR,
           colourTertiary:VARIABLES_COLOUR
        }
      },
      categoryStyles : {
      },
      componentStyles : {
         workspaceBackgroundColour: '#eee'
      },
      fontStyle:{
          family: "Comfortaa",
          weight: "normal",
          size: 10
      }
   }
}
